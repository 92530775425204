import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";

import Skeleton from "../Skeleton";
import Modal from "../../../Modal";
import useCoachingList from "../../customHooks/useCoachingList";
import TableRow from "../UiComponents/TableRow";
import Header from "Components/Dashboard/List/Header";
import { setModalId, showModal } from "actions/modal";
import { getCoaching, emptyCoachingList } from "actions/coaching";

export const ViewAllItems = () => {
  const dispatch = useDispatch();

  const [switchStatus, setSwitchStatus] = useState(true);
  const [lang, setLang] = useState("de");
  const [callStart, setCallStart] = useState(0);

  const coachingList = useCoachingList(lang, callStart);
  const modalStatus = useSelector((state) => state.modal.visibility);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const totalHeight = document.body.offsetHeight;
      const scrollPos = window.scrollY;

      if (
        scrollPos + 10 > totalHeight - windowHeight &&
        windowHeight > 400 &&
        coachingList.length >= callStart
      ) {
        setCallStart(callStart + 15);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [callStart, coachingList]);

  function onEditClick(id) {
    dispatch(showModal());
    dispatch(getCoaching(id));
    dispatch(setModalId(id));
  }

  const handleChange = (event) => {
    setSwitchStatus(event.target.checked);
    event.target.checked ? setLang("de") : setLang("en");
    dispatch(emptyCoachingList());
    setCallStart(0);
  };

  const renderTableRow = () => {
    return coachingList.map((item, index) => {
      return (
        <TableRow
          index={index}
          key={`coaching${item._id}`}
          title={item.title}
          year={item.year}
          week={item.weekNumber}
          category={item.category}
          onEditClick={onEditClick}
          id={item._id}
        />
      );
    });
  };

  return (
    <Skeleton>
      <Header
        home
        title="Spiri Coaching"
        handleChange={handleChange}
        switchStatus={switchStatus}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Category</th>
            <th>Year</th>
            <th>Week</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderTableRow()}</tbody>
      </Table>

      {modalStatus ? <Modal type="coaching" title="Spiri Coaching" /> : ""}
    </Skeleton>
  );
};

export default ViewAllItems;
