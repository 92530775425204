import {
  UPLOAD_HEALTH,
  GET_HEALTH,
  UPDATE_HEALTH,
  GET_ALL_HEALTH,
  EMPTY_STATE_HEALTH,
  MESSAGE,
} from "./types";
import spirify from "../apis/spirify";

export const uploadHealth = (data) => async (dispatch) => {
  try {
    const response = await spirify.post("spiri-health/add", data);

    const formObj = {};
    data.forEach((value, key) => {
      if (key !== "file") {
        formObj[key] = value;
      }
    });

    formObj.createdAt = new Date().toISOString();
    const payload = { ...formObj, ...response.data.data };

    if (payload.language === "de") {
      dispatch({ type: UPLOAD_HEALTH, payload });
    }

    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Sipri Health uploaded successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Error while uploading Spiri Health",
        display: true,
      },
    });
  }
};

export const getHealthList = (language, start) => async (dispatch) => {
  try {
    const response = await spirify.post(
      `dashboard/spiri-health/language/${start}/15`,
      {
        language,
      }
    );

    dispatch({ type: GET_ALL_HEALTH, payload: response.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Something went wrong",
        display: true,
      },
    });
  }
};

export const getHealth = (id) => async (dispatch) => {
  try {
    const response = await spirify.get(`spiri-health/detail/${id}`);

    dispatch({ type: GET_HEALTH, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Something  went wrong",
        display: true,
      },
    });
  }
};

export const emptyStateHealth = () => {
  return {
    type: EMPTY_STATE_HEALTH,
    payload: {},
  };
};

export const updateHealth = (data, date) => async (dispatch) => {
  try {
    const result = await spirify.put("spiri-health/update", data);
    console.log(result);

    if (result.data.success) {
      const formObj = {};
      data.forEach((value, key) => {
        if (key === "loveLetterId") {
          formObj["_id"] = value;
        } else {
          formObj[key] = value;
        }
      });

      const payload = { ...formObj, createdAt: date };

      dispatch({ type: UPDATE_HEALTH, payload });
      dispatch({
        type: MESSAGE,
        payload: {
          severity: "success",
          text: "Spiri Health updated successfully",
          display: true,
        },
      });
    } else {
      dispatch({
        type: MESSAGE,
        payload: {
          severity: "error",
          text: result.data.message,
          display: true,
        },
      });
    }
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Failed to update Spiri Health",
        display: true,
      },
    });
  }
};
