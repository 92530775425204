import {
  UPLOAD_PODCAST,
  GET_PODCASTS,
  GET_PODCAST,
  EMPTY_STATE_PODCAST,
  MESSAGE,
  UPDATE_PODCAST,
} from "./types";
import spirify from "../apis/spirify";
import { getRecentData } from "./recent";


export const uploadPodcast = (data) => async (dispatch) => {
  try {
    const response = await spirify.post("spiri-podcast/add", data);

    const formObj = {};
    data.forEach((value, key) => {
      if (key !== "file") {
        formObj[key] = value;
      }
    });
    formObj.createdAt = new Date().toISOString();
    const payload = { ...formObj, ...response.data.data };

    if (payload.language === "de") {
      dispatch({ type: UPLOAD_PODCAST, payload: payload });
    }

    // de because even en form switch de after submit

    dispatch(getRecentData({ language: payload.language, type: "spiriPodcast" }));

    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Podcast uploaded successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Error while uploading podcast",
        display: true,
      },
    });
  }
};

export const getPodcasts = (language, start) => async (dispatch) => {
  try {
    const response = await spirify.post(
      `dashboard/spiri-podcast/language/${start}/15`,
      { language }
    );

    dispatch({ type: GET_PODCASTS, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Something went wrong",
        display: true,
      },
    });
  }
};

export const getPodcast = (id) => async (dispatch) => {
  try {
    const response = await spirify.get(`dashboard/spiri-podcast/detail/${id}`);

    dispatch({ type: GET_PODCAST, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: "Error while fetching details",
      display: true,
    });
  }
};

export const updatePodcast = (data, date) => async (dispatch) => {
  try {
    await spirify.put("spiri-podcast/update", data);
    const formObj = {};
    data.forEach((value, key) => {
      if (key === "spiriPodcastId") {
        formObj["_id"] = value;
      } else {
        formObj[key] = value;
      }
    });

    const payload = { ...formObj, createdAt: date };

    dispatch({ type: UPDATE_PODCAST, payload });
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Podcast updated successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Failed to update podcast",
        display: true,
      },
    });
  }
};

export const emptyStatePodcast = () => {
  return {
    type: EMPTY_STATE_PODCAST,
    payload: {},
  };
};
