import {
  UPLOAD_NEWS,
  GET_NEWS,
  EMPTY_STATE_NEWS,
  MESSAGE,
  UPDATE_NEWS,
  GET_NEWS_LIST,
} from "./types";
import spirify from "../apis/spirify";

export const uploadNews = (data) => async (dispatch) => {
  try {
    const response = await spirify.post("spiri-news/add", data);

    const formObj = {};
    data.forEach((value, key) => {
      if (key !== "file") {
        formObj[key] = value;
      }
    });
    formObj.createdAt = new Date().toISOString();
    const payload = { ...formObj, ...response.data.data };

    if (payload.language === "de") {
      dispatch({ type: UPLOAD_NEWS, payload: payload });
    }

    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "News uploaded successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Error while uploading news",
        display: true,
      },
    });
  }
};

export const getNewsList = (language, start) => async (dispatch) => {
  try {
    const response = await spirify.post(
      `dashboard/spiri-news/language/${start}/15`,
      { language }
    );

    dispatch({ type: GET_NEWS_LIST, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Something went wrong",
        display: true,
      },
    });
  }
};

export const getNews = (id) => async (dispatch) => {
  try {
    const response = await spirify.get(`dashboard/spiri-news/detail/${id}`);
    dispatch({ type: GET_NEWS, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: "Error while fetching details",
      display: true,
    });
  }
};

export const updateNews = (data, date) => async (dispatch) => {
  try {
    await spirify.put("spiri-news/update", data);
    const formObj = {};
    data.forEach((value, key) => {
      if (key === "spiriNewsId") {
        formObj["_id"] = value;
      } else {
        formObj[key] = value;
      }
    });

    const payload = { ...formObj, createdAt: date };

    dispatch({ type: UPDATE_NEWS, payload });
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "News updated successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Failed to update News",
        display: true,
      },
    });
  }
};

export const emptyStateNews = () => {
  return {
    type: EMPTY_STATE_NEWS,
    payload: {},
  };
};
