export const ROOT = "/";
export const LOGIN = "/login";
export const UPLOAD_VIDEO = "/uploadVideo";
export const UPLOAD_PODCAST = "/uploadPodcast";
export const UPLOAD_LETTER = "/uploadLetter";
export const UPLOAD_NEWS = "/uploadNews";
export const UPLOAD_HEALTH = "/uploadHealth";
export const LIST_VIDEO = "/listVideo";
export const LIST_PODCAST = "/listPodcast";
export const LIST_LETTER = "/listLetter";
export const LIST_NEWS = "/listNews";
export const LIST_HEALTH = "/listHealth";
export const ADD_CATEGORY = "/addCategory";
export const ALL_CATEGORY = "/allCategory";
export const COACHING_ADD_ITEM = "/coaching/addItem";
export const COACHING_VIEW_ALL_ITEM = "/coaching/viewItems";
