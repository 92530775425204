import React from "react";
import { useDispatch } from "react-redux";
import ReactDOM from "react-dom";
import { IoIosClose } from "react-icons/io";

import FormPodcast from "Components/Dashboard/Upload/FormPodcast";
import FormVideo from "Components/Dashboard/Upload/FormVideo";
import FormLetter from "Components/Dashboard/Upload/FormLetter";
import FormNews from "Components/Dashboard/Upload/FormNews";
import CatForm from "Components/Dashboard/Category/CatForm";
import CoachingForm from "Components/Dashboard/Coaching/CoachingForm";
import FormHealth from "Components/Dashboard/Upload/FormHealth";
import { hideModal } from "actions/modal";

import styles from "./Modal.module.scss";

const Modal = ({ title, type }) => {
  const dispatch = useDispatch();

  function onDismiss() {
    dispatch(hideModal());
  }

  const renderForm = () => {
    switch (type) {
      case "spiriVedio":
        return <FormVideo title={title} modal />;
      case "spiriPodcast":
        return <FormPodcast title={title} modal />;
      case "loveLetter":
        return <FormLetter title={title} modal />;
      case "spiriNews":
        return <FormNews title={title} modal />;
      case "category":
        return <CatForm title={title} modal />;
      case "coaching":
        return <CoachingForm title={title} modal />;
      case "spiriHealth":
        return <FormHealth title={title} modal />;

      default:
        return false;
    }
  };

  return ReactDOM.createPortal(
    <div onClick={onDismiss} className={styles.modalContainer}>
      <div onClick={(e) => e.stopPropagation()} className={styles.modal}>
        <button onClick={onDismiss} className={styles.close}>
          <IoIosClose />
        </button>

        {renderForm()}
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

export default Modal;
