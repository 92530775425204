import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllCategory } from "actions/category";

const useCategoriesList = (type) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategory(type));
    // eslint-disable-next-line
  }, [type]);

  return useSelector((state) => Object.values(state.category));
};

export default useCategoriesList;
