import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDashDetail } from "actions/home";

const useHomeData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashDetail());
    // eslint-disable-next-line
  }, []);

  return useSelector((state) => state.home);
};

export default useHomeData;
