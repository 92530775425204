import { GET_RECENT_DATA, MESSAGE } from "./types";
import spirify from "../apis/spirify";

export const getRecentData = (data) => async (dispatch) => {
  try {
    const response = await spirify.post("dashboard/recentLPV", data);
    dispatch({ type: GET_RECENT_DATA, payload: response.data });
  } catch (e) {
    console.log(e);
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Something went wrong",
        display: true,
      },
    });
  }
};
