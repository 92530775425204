import { mapKeys } from "lodash";

import {
  GET_LOVELETTERS,
  UPLOAD_LOVELETTER,
  GET_LOVELETTER,
  EMPTY_STATE_LOVELETTER,
  UPDATE_LOVELETTER,
} from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_LOVELETTERS:
      return { ...state, ...mapKeys(action.payload.data, "_id") };
    case UPLOAD_LOVELETTER:
      return { [action.payload._id]: action.payload, ...state };
    case GET_LOVELETTER:
      return { ...state, [action.payload._id]: action.payload };
    case UPDATE_LOVELETTER:
      return { ...state, [action.payload._id]: action.payload };
    case EMPTY_STATE_LOVELETTER:
      return action.payload;
    default:
      return state;
  }
}
