import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPodcasts } from "actions/podcast";

const usePodcastList = (lang, start) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPodcasts(lang, start));
    // eslint-disable-next-line
  }, [lang, start]);

  return useSelector((state) => Object.values(state.podcast));
};

export default usePodcastList;
