import React from "react";

import { Form } from "react-bootstrap";

const Input = ({
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  value,
  errors,
  touched,
  readOnly,
  text,
}) => {
  return (
    <Form.Group controlId={`form-${name}`}>
      <Form.Label>{placeholder}</Form.Label>
      <Form.Control
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        readOnly={readOnly}
        value={value}
        onBlur={onBlur}
        isValid={touched && !errors}
        isInvalid={touched && errors}
        min={type === "number" ? "0" : null}
      ></Form.Control>
      <Form.Text className="text-muted">{text}</Form.Text>
      <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default Input;
