import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";

import TableRow from "../UiComponents/TableRow";
import Modal from "../../../Modal";
import Skeleton from "../Skeleton";
import useLoveletterList from "Components/customHooks/useLoveletterList";
import useVideoList from "Components/customHooks/useVideoList";
import usePodcastList from "Components/customHooks/usePodcastList";
import useNewsList from "Components/customHooks/useNewsList";
import useHealthList from "Components/customHooks/useHealthList";
import Header from "Components/Dashboard/List/Header";
import { getLoveletter, emptyStateLoveletter } from "actions/loveletter";
import { getVideo, emptyStateVideo } from "actions/video";
import { getPodcast, emptyStatePodcast } from "actions/podcast";
import { getNews, emptyStateNews } from "actions/news";
import { getHealth, emptyStateHealth } from "actions/health";
import { setModalId, showModal } from "actions/modal";

import { getFormatedDate } from "utils";

import styles from "./Home.module.scss";

const Home = () => {
  const dispatch = useDispatch();

  const [switchStatusLetter, setSwitchStatusLetter] = useState(true);
  const [langLetter, setLangLetter] = useState("de");
  const [switchStatusVideo, setSwitchStatusVideo] = useState(true);
  const [switchStatusHealth, setSwitchStatusHealth] = useState(true);
  const [langVideo, setLangVideo] = useState("de");
  const [langNews, setLangNews] = useState("de");
  const [langHealth, setLangHealth] = useState("de");
  const [switchStatusPodcast, setSwitchStatusPodcast] = useState(true);
  const [switchStatusNews, setSwitchStatusNews] = useState(true);
  const [langPodcast, setLangPodcast] = useState("de");
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const modalStatus = useSelector((state) => state.modal.visibility);

  function onEditClick(id, type, title) {
    dispatch(showModal());
    setModalType(type);
    setModalTitle(title);
    dispatch(setModalId(id));

    if (type === "spiriVedio") {
      dispatch(getVideo(id));
    } else if (type === "loveLetter") {
      dispatch(getLoveletter(id));
    } else if (type === "spiriNews") {
      dispatch(getNews(id));
    } else if (type === "spiriHealth") {
      dispatch(getHealth(id));
    } else {
      dispatch(getPodcast(id));
    }
  }

  const handleChangeLetter = (event) => {
    setSwitchStatusLetter(event.target.checked);
    //emptying the local state
    dispatch(emptyStateLoveletter());
    event.target.checked ? setLangLetter("de") : setLangLetter("en");
  };

  const handleChangeHealth = (event) => {
    setSwitchStatusHealth(event.target.checked);
    //emptying the local state
    dispatch(emptyStateHealth());
    event.target.checked ? setLangHealth("de") : setLangHealth("en");
  };

  const handleChangeVideo = (event) => {
    setSwitchStatusVideo(event.target.checked);
    //emptying the local state
    dispatch(emptyStateVideo());
    event.target.checked ? setLangVideo("de") : setLangVideo("en");
  };

  const handleChangePodcast = (event) => {
    setSwitchStatusPodcast(event.target.checked);
    //emptying the local state
    dispatch(emptyStatePodcast());
    event.target.checked ? setLangPodcast("de") : setLangPodcast("en");
  };

  const handleChangeNews = (event) => {
    setSwitchStatusNews(event.target.checked);
    //emptying the local state
    dispatch(emptyStateNews());
    event.target.checked ? setLangNews("de") : setLangNews("en");
  };

  const letterList = useLoveletterList(langLetter, 0);

  const renderTableRowLetter = () => {
    return letterList.slice(0, 5).map((item, index) => {
      return (
        <TableRow
          index={index}
          key={`list${item._id}`}
          title={item.title}
          date={getFormatedDate(item.createdAt)}
          category={item.category}
          onEditClick={onEditClick}
          id={item._id}
          type="loveLetter"
          modalTitle="Love letter"
        />
      );
    });
  };

  const videos = useVideoList(langVideo, 0);

  const renderTableRowVideo = () => {
    return videos.slice(0, 5).map((item, index) => {
      return (
        <TableRow
          index={index}
          key={`video${item._id}`}
          title={item.title}
          date={getFormatedDate(item.createdAt)}
          category={item.category}
          onEditClick={onEditClick}
          id={item._id}
          type="spiriVedio"
          modalTitle="Spiri Video"
        />
      );
    });
  };

  const podcastList = usePodcastList(langPodcast, 0);

  const renderTableRowPodcast = () => {
    return podcastList.slice(0, 5).map((item, index) => {
      return (
        <TableRow
          index={index}
          key={`podcast${item._id}`}
          title={item.title}
          date={getFormatedDate(item.createdAt)}
          category={item.category}
          onEditClick={onEditClick}
          id={item._id}
          type="spiriPodcast"
          modalTitle="Spiri Podcast"
        />
      );
    });
  };

  const newsList = useNewsList(langNews, 0);

  const renderTableRowNews = () => {
    return newsList.slice(0, 5).map((item, index) => {
      return (
        <TableRow
          index={index}
          key={`podcast${item._id}`}
          title={item.title}
          date={getFormatedDate(item.createdAt)}
          category={item.category}
          onEditClick={onEditClick}
          id={item._id}
          type="spiriNews"
          modalTitle="Spiri News"
        />
      );
    });
  };

  const healthList = useHealthList(langHealth, 0);

  const renderTableRowHealth = () => {
    return healthList.slice(0, 5).map((item, index) => {
      return (
        <TableRow
          index={index}
          key={`podcast${item._id}`}
          title={item.title}
          date={getFormatedDate(item.createdAt)}
          category={item.category}
          onEditClick={onEditClick}
          id={item._id}
          type="spiriHealth"
          modalTitle="Spiri Health"
        />
      );
    });
  };

  const renderHeader = (title, change, switchStatus) => {
    return (
      <Header
        title={title}
        home
        handleChange={change}
        switchStatus={switchStatus}
      />
    );
  };

  const renderRootTable = () => {
    return (
      <>
        {renderHeader("Spiri Video", handleChangeVideo, switchStatusVideo)}

        <Table striped bordered hover>
          <tbody>{renderTableRowVideo()}</tbody>
        </Table>

        <div className={styles.viewAll}>
          <Link to="/listVideo">View All</Link>
        </div>

        {renderHeader(
          "Spiri Podcast",
          handleChangePodcast,
          switchStatusPodcast
        )}

        <Table striped bordered hover>
          <tbody>{renderTableRowPodcast()}</tbody>
        </Table>
        <div className={styles.viewAll}>
          <Link to="/listPodcast">View All</Link>
        </div>

        {renderHeader("Spiri Letter", handleChangeLetter, switchStatusLetter)}

        <Table striped bordered hover>
          <tbody>{renderTableRowLetter()}</tbody>
        </Table>
        <div className={styles.viewAll}>
          <Link to="/listLetter">View All</Link>
        </div>

        <Header
          title="Spiri News"
          home
          handleChange={handleChangeNews}
          switchStatus={switchStatusNews}
        />
        <Table striped bordered hover>
          <tbody>{renderTableRowNews()}</tbody>
        </Table>
        <div className={styles.viewAll}>
          <Link to="/listNews">View All</Link>
        </div>

        {renderHeader("Spiri Health", handleChangeHealth, switchStatusHealth)}

        <Table striped bordered hover>
          <tbody>{renderTableRowHealth()}</tbody>
        </Table>
        <div className={styles.viewAll}>
          <Link to="/listLetter">View All</Link>
        </div>

        {modalStatus ? <Modal title={modalTitle} type={modalType} /> : ""}
      </>
    );
  };

  return <Skeleton page="root">{renderRootTable()}</Skeleton>;
};

export default Home;
