import React, { useState, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { string, object } from "yup";
import Switch from "@material-ui/core/Switch";
import LinearProgress from "@material-ui/core/LinearProgress";

import Input from "../UiComponents/Form/Input";
import Select from "../UiComponents/Form/Select";
import Description from "../UiComponents/Form/Description";
import { uploadVideo, updateVideo } from "actions/video";
import useCategoriesList from "Components/customHooks/useCategoriesList";
import { hideModal } from "actions/modal";

import styles from "./Upload.module.css";

const FormVideo = ({ title, modal }) => {
  const dispatch = useDispatch();

  const type = "spiriVedio";

  // State
  const [lang, setLang] = useState("de");
  const [file, setFile] = useState(1);
  const [loaderDisplay, setLoaderDisplay] = useState(0);

  //redux state
  const messageDisplay = useSelector((state) => state.message.display);

  const modalId = useSelector((state) => state.modal.id);
  const vedioDetail = useSelector((state) => state.video[modalId]);

  const modalSwitchStatus =
    vedioDetail && vedioDetail.language === "en" ? false : true;

  const [switchStatus, setSwitchStatus] = useState(
    modal ? modalSwitchStatus : true
  );

  useEffect(() => {
    // for loader to stop after form is submitted
    setLoaderDisplay(0);

    if (window.location.pathname === "/uploadVideo") {
      window.scrollTo(0, 0);
    }
  }, [messageDisplay]);

  const subCategories = useCategoriesList(type);

  //modifying intial value on the basis of edit or add
  let initialValues = {
    title: modal ? vedioDetail && vedioDetail.title : "",
    description: modal ? vedioDetail && vedioDetail.description : "",
    type: type,
    category: modal ? vedioDetail && vedioDetail.category : "",
    subcategory: modal ? vedioDetail && vedioDetail.subcategory : "",
    content: modal ? vedioDetail && vedioDetail.content : "",
    vedioLength: modal ? vedioDetail && vedioDetail.vedioLength : "",
    language: modal ? vedioDetail && vedioDetail.language : lang, // intialvalue is called in first render, so declare value at render method,
    day: modal ? vedioDetail && vedioDetail.day : "",
    week: modal ? vedioDetail && vedioDetail.week : "",
    year: modal ? vedioDetail && vedioDetail.year : "",
  };

  let schema = object({
    title: string().required("Required"),
    description: string().required("Required"),
    category: string().required("Required"),
    subcategory: string().required("Required"),
    content: string().required("Required"),
    vedioLength: string().required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = new FormData(); // Currently empty
    console.log(values);

    for (const key in values) {
      formData.append(key, values[key]);
    }
    formData.append("file", file);

    if (modal) {
      formData.append(`${type}Id`, modalId);
    }

    if (modal && (file === 0 || file === 1)) {
      formData.delete("file");
      formData.append("vedioUrl", vedioDetail.vedioUrl);
      formData.append("thumbnail", vedioDetail.thumbnail);
    }

    if (values.content !== "scheduled") {
      formData.delete("day");
      formData.delete("week");
      formData.delete("year");
    }

    modal
      ? dispatch(updateVideo(formData, vedioDetail.createdAt))
      : dispatch(uploadVideo(formData));

    resetForm();

    if (!modal) {
      setLoaderDisplay(1);
      setFile(1);
      document.querySelector("#file").value = "";
    } else {
      dispatch(hideModal());
    }
  };

  const renderSubcategoriesOption = () => {
    return subCategories.map((item) => {
      return (
        <option key={`type-${item._id}`} value={item._id}>
          {item.subcategory}
        </option>
      );
    });
  };

  const handleFileChange = (event) => {
    const fileArray =
      event.currentTarget.files[0] &&
      event.currentTarget.files[0].name.split(".");

    const fileExt = fileArray && fileArray[fileArray && fileArray.length - 1];
    if (fileExt === "mp4" || fileExt === "m4v") {
      setFile(event.currentTarget.files[0]);
    } else {
      // for showing error message
      // 0 means focused and file not there
      setFile(0);
    }
  };

  const handleChangeSwitch = (event) => {
    //do not allow switch change if editing
    // user can not change lang while editing
    if (!modal) {
      setSwitchStatus(event.target.checked);
      event.target.checked ? setLang("de") : setLang("en");
    }
  };

  const btnStatus = (isValid, dirty) => {
    // checking if upload or edit

    if (!modal) {
      return !(isValid && dirty && file && file !== 1);
    }
    return false;
  };

  return (
    <Card className={modal ? styles.fixedHeight : false}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onFormSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isValid,
          dirty,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Header>
              <Card.Title
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {title} - {lang}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="langContainer">
                <p>EN</p>
                <Switch
                  checked={switchStatus}
                  onChange={handleChangeSwitch}
                  color="primary"
                  name="checked"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <p>DE</p>
              </div>
              <Input
                name="title"
                type="text"
                placeholder="Title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                touched={touched.title}
                errors={errors.title}
              />

              <Description
                title="Description"
                name="description"
                as="textarea"
                rows="3"
                placeholder="Enter ..."
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                touched={touched.description}
                errors={errors.description}
              />

              <Select
                title="Category"
                name="category"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.category}
                touched={touched.category}
                errors={errors.category}
              >
                <option value="">Choose...</option>
                <option value="love">Love</option>
                <option value="mind">Mind</option>
                <option value="soul">Soul</option>
                <option value="life">Life</option>
              </Select>

              <Select
                title="Sub Category"
                name="subcategory"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.subcategory}
                touched={touched.subcategory}
                errors={errors.subcategory}
              >
                <option value="">Choose...</option>
                {renderSubcategoriesOption()}
              </Select>

              <Select
                title="Content"
                name="content"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.content}
                touched={touched.content}
                errors={errors.content}
              >
                <option value="">Choose...</option>
                <option value="preview">Preview</option>
                <option value="basic">Basic</option>
                <option value="scheduled">Scheduled</option>
              </Select>

              {values.content === "scheduled" ? (
                <>
                  <Input
                    name="day"
                    type="number"
                    placeholder="Day"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.day}
                    touched={touched.day}
                    errors={errors.day}
                  />
                  <Input
                    name="week"
                    type="number"
                    placeholder="Week"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.week}
                    touched={touched.week}
                    errors={errors.week}
                  />
                  <Input
                    name="year"
                    type="number"
                    placeholder="Year"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.year}
                    touched={touched.year}
                    errors={errors.year}
                  />
                </>
              ) : null}

              <Form.File className="mb-3" id="file">
                <Form.File.Label>Upload Video</Form.File.Label>
                <Form.File.Input
                  id="file"
                  name="file"
                  onBlur={handleBlur}
                  onChange={handleFileChange}
                />
                <Form.Text className="text-muted">
                  format: mp4 and m4v, ideal resolution: 1280X720
                </Form.Text>
                <p className={styles.feedback} type="valid">
                  {file ? null : "Invalid File Format"}
                </p>
              </Form.File>
              <Input
                name="vedioLength"
                type="text"
                placeholder="Video Length"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.vedioLength}
                touched={touched.vedioLength}
                errors={errors.vedioLength}
                text={"e.g.  02hrs 10mins 30sec"}
              />
            </Card.Body>
            <Card.Footer>
              <Button
                disabled={btnStatus(isValid, dirty)}
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </Card.Footer>
            {loaderDisplay ? <LinearProgress /> : false}
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default FormVideo;
