import { mapKeys } from "lodash";

import {
  ADD_CATEGORY,
  GET_ALL_CATEGORY,
  UPDATE_CATEGORY,
} from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_CATEGORY:
      return { ...state, [action.payload._id]: action.payload };
    case GET_ALL_CATEGORY:
      return { ...mapKeys(action.payload.data, "_id") };
    case UPDATE_CATEGORY:
      return { ...state, [action.payload._id]: action.payload };
    default:
      return state;
  }
}
