import { mapKeys } from "lodash";
import {
  GET_WEEK_AND_YEAR,
  GET_COACHING_LIST,
  ADD_COACHING,
  UPDATE_COACHING,
  GET_COACHING,
  EMPTY_COACHING_LIST,
} from "../actions/types";

const INITIAL_STATE = {
  weekYear: {},
  list: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_WEEK_AND_YEAR:
      return { ...state, weekYear: action.payload };
    case GET_COACHING_LIST:
      return {
        ...state,
        list: { ...state.list, ...mapKeys(action.payload.data, "_id") },
      };
    case ADD_COACHING:
      return {
        weekYear: state.weekYear,
        list: { [action.payload._id]: action.payload, ...state.list },
      };
    case GET_COACHING:
      return {
        weekYear: state.weekYear,
        list: { ...state.list, [action.payload._id]: action.payload },
      };
    case UPDATE_COACHING:
      return {
        weekYear: state.weekYear,
        list: { ...state.list, [action.payload._id]: action.payload },
      };
    case EMPTY_COACHING_LIST:
      return {
        weekYear: state.weekYear,
        list: action.payload,
      };
    default:
      return state;
  }
}
