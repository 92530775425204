import React from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import BackBtn from "../UiComponents/BackBtn";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import InfoDisplay from "./InfoDisplay";
import { hideSideBar } from "actions/sideBar";

import styles from "./Skeleton.module.scss";

export const Skeleton = (props) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(hideSideBar());
  };

  return (
    <div>
      <SideBar />
      <section onClick={(e) => handleClick(e)} className={styles.container}>
        <Container fluid>
          <Row>
            <Col>
              <TopBar />
            </Col>
          </Row>
          {props.page === "root" ? <InfoDisplay /> : ""}
          {props.page === "root" ? "" : <BackBtn />}

          <Row>
            <Col>{props.children}</Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Skeleton;
