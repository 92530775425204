import React from "react";

import Skeleton from "Components/Dashboard/Skeleton";
import FormLetter from "./FormLetter";

const UploadLetter = () => {
  return (
    <div>
      <Skeleton>
        <FormLetter title="Spiri Loveletter" />
      </Skeleton>
    </div>
  );
};

export default UploadLetter;
