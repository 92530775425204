import {
  GET_WEEK_AND_YEAR,
  MESSAGE,
  GET_COACHING_LIST,
  ADD_COACHING,
  GET_COACHING,
  UPDATE_COACHING,
  EMPTY_COACHING_LIST,
} from "./types";
import spirify from "../apis/spirify";

const ERROR = "Something went wrong";

export const getDayWeek = (lang) => async (dispatch) => {
  try {
    const response = await spirify.post(
      "dashboard/spiri-coaching/recent",
      lang
    );
    dispatch({ type: GET_WEEK_AND_YEAR, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: ERROR,
        display: true,
      },
    });
  }
};

export const uploadSpiriCoaching = (data) => async (dispatch) => {
  try {
    const response = await spirify.post("spiri-coaching/add", data);

    const formObj = {};
    data.forEach((value, key) => {
      if (key !== "file") {
        formObj[key] = value;
      }
    });
    formObj.createdAt = new Date().toISOString();
    const payload = { ...formObj, ...response.data.data };

    if (payload.language === "de") {
      dispatch({ type: ADD_COACHING, payload });
    }

    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Coaching uploaded successfully",
        display: true,
      },
    });

    // to get updated year and week

    try {
      const lang = { language: payload.language };
      const response = await spirify.post(
        "dashboard/spiri-coaching/recent",
        lang
      );
      dispatch({ type: GET_WEEK_AND_YEAR, payload: response.data.data });
    } catch (e) {
      dispatch({
        type: MESSAGE,
        payload: {
          severity: "error",
          text: ERROR,
          display: true,
        },
      });
    }
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Error while uploading Coaching",
        display: true,
      },
    });
  }
};

export const getCoachingList = (language, start) => async (dispatch) => {
  try {
    const response = await spirify.post(
      `dashboard/spiri-coaching/language/${start}/15`,
      {
        language,
      }
    );

    dispatch({ type: GET_COACHING_LIST, payload: response.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: ERROR,
        display: true,
      },
    });
  }
};

export const getCoaching = (id) => async (dispatch) => {
  try {
    const response = await spirify.get(`dashboard/spiri-coaching/detail/${id}`);

    dispatch({ type: GET_COACHING, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: ERROR,
        display: true,
      },
    });
  }
};

export const updateCoaching = (data, date) => async (dispatch) => {
  try {
    await spirify.put("spiri-coaching/update", data);
    const formObj = {};
    data.forEach((value, key) => {
      if (key === "coachingId") {
        formObj["_id"] = value;
      } else {
        formObj[key] = value;
      }
    });

    const payload = { ...formObj, createdAt: date };

    dispatch({ type: UPDATE_COACHING, payload });
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Coaching updated successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Error while uploading Coaching",
        display: true,
      },
    });
  }
};

export const emptyCoachingList = () => {
  return {
    type: EMPTY_COACHING_LIST,
    payload: {},
  };
};
