import React from "react";

import Skeleton from "Components/Dashboard/Skeleton";
import FormVideo from "./FormVideo";

const UploadVideo = (props) => {
  return (
    <Skeleton>
      <FormVideo title="Spiri Video" />
    </Skeleton>
  );
};

export default UploadVideo;
