// Auth
export const AUTH_USER = "AUTH_USER";

// category
export const ADD_CATEGORY = "ADD_CATEGORY";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

// Video
export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const GET_VIDEOS = "GET_VIDEOS";
export const GET_VIDEO = "GET_VIDEO";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const EMPTY_STATE_VIDEO = "EMPTY_STATE_VIDEO";

// Podcast
export const UPLOAD_PODCAST = "UPLOAD_PODCAST";
export const GET_PODCASTS = "GET_PODCASTS";
export const GET_PODCAST = "GET_PODCAST";
export const UPDATE_PODCAST = "UPDATE_PODCAST";
export const EMPTY_STATE_PODCAST = "EMPTY_STATE_PODCAST";

// Loveletter
export const UPLOAD_LOVELETTER = "UPLOAD_LETTER";
export const GET_LOVELETTERS = "GET_LOVELETTERS";
export const GET_LOVELETTER = "GET_LOVELETTER";
export const UPDATE_LOVELETTER = "UPDATE_LOVELETTER";
export const EMPTY_STATE_LOVELETTER = "EMPTY_STATE_LOVELETTER";

// News
export const UPLOAD_NEWS = "UPLOAD_NEWS";
export const GET_NEWS_LIST = "GET_NEWS_LIST";
export const GET_NEWS = "GET_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const EMPTY_STATE_NEWS = "EMPTY_STATE_NEWS";

// health
export const UPLOAD_HEALTH = "UPLOAD_HEALTH";
export const GET_ALL_HEALTH = "GET_ALL_HEALTH";
export const GET_HEALTH = "GET_HEALTH";
export const UPDATE_HEALTH = "UPDATE_HEALTH";
export const EMPTY_STATE_HEALTH = "EMPTY_STATE_HEALTH";

// Coaching
export const GET_WEEK_AND_YEAR = "GET_WEEK_AND_YEAR";
export const ADD_COACHING = "ADD_COACHING";
export const GET_COACHING_LIST = "GET_COACHING_LIST";
export const GET_COACHING = "GET_COACHING";
export const UPDATE_COACHING = "UPDATE_COACHING";
export const EMPTY_COACHING_LIST = "EMPTY_COACHING_LIST";

// Message
export const MESSAGE = "MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// Modal
export const SET_MODAL_ID = "MODAL_ID";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

// Home
export const DASH_DETAIL = "DASH_DETAIL";

// SideBar

export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";
export const TOOGLE_SIDEBAR = "TOOGLE_SIDEBAR";

// recent

export const GET_RECENT_DATA = "GET_RECENT_DATA";
