import React, { useState, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { string, object } from "yup";
import Switch from "@material-ui/core/Switch";
import LinearProgress from "@material-ui/core/LinearProgress";

import Input from "../UiComponents/Form/Input";
import Select from "../UiComponents/Form/Select";
import Description from "../UiComponents/Form/Description";
import { hideModal } from "actions/modal";
import { uploadPodcast, updatePodcast } from "../../../actions/podcast";
import useCategoriesList from "Components/customHooks/useCategoriesList";

import styles from "./Upload.module.css";

const FormPodcast = ({ title, modal }) => {
  const dispatch = useDispatch();

  const type = "spiriPodcast";

  // State
  const [lang, setLang] = useState("de");
  const [file, setFile] = useState(1);
  const [file1, setFile1] = useState(1);
  const [loaderDisplay, setLoaderDisplay] = useState(0);

  //redux state
  const modalId = useSelector((state) => state.modal.id);
  const podcastDetail = useSelector((state) => state.podcast[modalId]);
  const modalSwitchStatus =
    podcastDetail && podcastDetail.language === "en" ? false : true;

  const messageDisplay = useSelector((state) => state.message.display);

  const [switchStatus, setSwitchStatus] = useState(
    modal ? modalSwitchStatus : true
  );

  useEffect(() => {
    // for loader to stop after form is submitted
    setLoaderDisplay(0);

    if (window.location.pathname === "/uploadPodcast") {
      window.scrollTo(0, 0);
    }
  }, [messageDisplay]);

  const subCategories = useCategoriesList(type);

  //modifying intial value on the basis of edit or add

  const initialValues = {
    title: modal ? podcastDetail && podcastDetail.title : "",
    description: modal ? podcastDetail && podcastDetail.description : "",
    type: type,
    category: modal ? podcastDetail && podcastDetail.category : "",
    subcategory: modal ? podcastDetail && podcastDetail.subcategory : "",
    content: modal ? podcastDetail && podcastDetail.content : "",
    language: modal ? podcastDetail && podcastDetail.language : lang, // intialvalue is called in first render, so declare value at render method
    mp3Length: modal ? podcastDetail && podcastDetail.mp3Length : "",
    day: modal ? podcastDetail && podcastDetail.day : "",
    week: modal ? podcastDetail && podcastDetail.week : "",
    year: modal ? podcastDetail && podcastDetail.year : "",
  };

  const schema = object({
    title: string().required("Required"),
    description: string().required("Required"),
    category: string().required("Required"),
    subcategory: string().required("Required"),
    content: string().required("Required"),
    mp3Length: string().required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = new FormData(); // Currently empty

    for (const key in values) {
      formData.append(key, values[key]);
    }
    if (file !== 1) {
      formData.append("file", file);
    }
    if (file1 !== 1) {
      formData.append("file", file1);
    }


    // conditional formdata based on update or upload
    if (modal) {
      formData.append(`${type}Id`, modalId);
    }

    if (modal && (file === 0 || file === 1)) {
      // formData.delete("file");
      formData.append("mp3Url", podcastDetail.mp3Url);
    }

    if (modal && (file1 === 0 || file1 === 1)) {
      // formData.delete("file");
      formData.append("thumbnail", podcastDetail.thumbnail);
    }

    if (values.content !== "scheduled") {
      formData.delete("releaseDate");
    }

    modal
      ? dispatch(updatePodcast(formData, podcastDetail.createdAt))
      : dispatch(uploadPodcast(formData));

    resetForm();

    if (!modal) {
      setLoaderDisplay(1);
      setFile(1);
      document.querySelector("#file").value = "";
      document.querySelector("#file1").value = "";
    } else {
      dispatch(hideModal());
    }
  };

  const renderSubcategoriesOption = () => {
    return subCategories.map((item) => {
      return (
        <option key={`type-${item._id}`} value={item._id}>
          {item.subcategory}
        </option>
      );
    });
  };

  const btnStatus = (isValid, dirty) => {
    // checking if upload or edit
    // file === 1 means focused but not selected

    if (!modal) {
      return !(isValid && dirty && file && file !== 1 && file && file !== 1);
    }
    return false;
  };

  const handleChangeSwitch = (event) => {
    //do not allow switch change if editing
    // user can not change lang while editing
    if (!modal) {
      setSwitchStatus(event.target.checked);
      event.target.checked ? setLang("de") : setLang("en");
    }
  };

  const handleChangeAudio = (event) => {
    const fileArray =
      event.currentTarget.files[0] &&
      event.currentTarget.files[0].name.split(".");

    const fileExt = fileArray && fileArray[fileArray && fileArray.length - 1];
    if (fileExt === "mp3") {
      setFile(event.currentTarget.files[0]);
    } else {
      // for showing error message
      // 0 means focused and file not there
      setFile(0);
    }
  };

  const handleChangeImage = (event) => {
    const fileArray =
      event.currentTarget.files[0] &&
      event.currentTarget.files[0].name.split(".");

    const fileExt = fileArray && fileArray[fileArray && fileArray.length - 1];
    if (fileExt === "png" || fileExt === "jpg") {
      setFile1(event.currentTarget.files[0]);
    } else {
      // for showing error message
      // 0 means focused and file not there
      setFile1(0);
    }
  };

  return (
    <Card className={modal ? styles.fixedHeight : false}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onFormSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isValid,
          dirty,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Header>
              <Card.Title
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {title} - {lang}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="langContainer">
                <p>EN</p>
                <Switch
                  checked={switchStatus}
                  onChange={handleChangeSwitch}
                  color="primary"
                  name="checked"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <p>DE</p>
              </div>
              <Input
                name="title"
                type="text"
                placeholder="Title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                touched={touched.title}
                errors={errors.title}
              />

              <Description
                title="Description"
                name="description"
                as="textarea"
                rows="3"
                placeholder="Enter ..."
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                touched={touched.description}
                errors={errors.description}
              />

              <Select
                title="Category"
                name="category"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.category}
                touched={touched.category}
                errors={errors.category}
              >
                <option value="">Choose...</option>
                <option value="love">Love</option>
                <option value="mind">Mind</option>
                <option value="soul">Soul</option>
                <option value="life">Life</option>
              </Select>

              <Select
                title="Sub Category"
                name="subcategory"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.subcategory}
                touched={touched.subcategory}
                errors={errors.subcategory}
              >
                <option value="">Choose...</option>
                {renderSubcategoriesOption()}
              </Select>

              <Select
                title="Content"
                name="content"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.content}
                touched={touched.content}
                errors={errors.content}
              >
                <option value="">Choose...</option>
                <option value="preview">Preview</option>
                <option value="basic">Basic</option>
                <option value="scheduled">Scheduled</option>
              </Select>

              {values.content === "scheduled" ? (
                <>
                  <Input
                    name="day"
                    type="number"
                    placeholder="Day"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.day}
                    touched={touched.day}
                    errors={errors.day}
                  />
                  <Input
                    name="week"
                    type="number"
                    placeholder="Week"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.week}
                    touched={touched.week}
                    errors={errors.week}
                  />
                  <Input
                    name="year"
                    type="number"
                    placeholder="Year"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.year}
                    touched={touched.year}
                    errors={errors.year}
                  />
                </>
              ) : null}

              <Form.File className="mb-3" id="file">
                <Form.File.Label>Upload Audio File</Form.File.Label>
                <Form.File.Input
                  id="file"
                  name="file"
                  onBlur={handleBlur}
                  onChange={handleChangeAudio}
                />
                <Form.Text className="text-muted">format: mp3</Form.Text>
                <p className={styles.feedback} type="valid">
                  {file ? null : "Invalid File Format"}
                </p>
              </Form.File>
              <Input
                name="mp3Length"
                type="text"
                placeholder="Mp3 Length"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mp3Length}
                touched={touched.mp3Length}
                errors={errors.mp3Length}
                text={"e.g.  02hrs 10mins 30sec"}
              />
              <Form.File className="mt-4 mb-4" id="file1">
                <Form.File.Label>Upload Image</Form.File.Label>
                <Form.File.Input
                  id="file1"
                  name="file1"
                  onBlur={handleBlur}
                  onChange={handleChangeImage}
                />
                <Form.Text className="text-muted">format: png, jpg</Form.Text>
                <p className={styles.feedback} type="valid">
                  {file1 ? null : "Invalid File Format"}
                </p>
              </Form.File>
            </Card.Body>
            <Card.Footer>
              <Button
                disabled={btnStatus(isValid, dirty)}
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </Card.Footer>
            {loaderDisplay ? <LinearProgress /> : false}
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default FormPodcast;
