import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "../history";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { clearMessage } from "actions/message";

import CustomRoute from "../routes/CustomRoute";
import Login from "./login/index";
import UploadVideo from "./Dashboard/Upload/UploadVideo";
import UploadLetter from "./Dashboard/Upload/UploadLetter";
import UploadPodcast from "./Dashboard/Upload/UploadPodcast";
import UploadNews from "./Dashboard/Upload/UploadNews";
import UploadHealth from "./Dashboard/Upload/UploadHealth";
import ListVideo from "./Dashboard/List/ListVideo";
import ListLetter from "./Dashboard/List/ListLetter";
import ListPodcast from "./Dashboard/List/ListPodcast";
import ListNews from "./Dashboard/List/ListNews";
import ListHealth from "./Dashboard/List/ListHealth";
import AddCategory from "./Dashboard/Category/AddCategory";
import AllCategory from "./Dashboard/Category/AllCategory";
import AddItem from "./Dashboard/Coaching/AddItem";
import ViewAllItems from "./Dashboard/Coaching/ViewAllItems";

import * as routes from "../routes";

import Dashboard from "./Dashboard";

import "./variables.scss";
import "./App.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const App = () => {
  const dispatch = useDispatch();

  const authenticated = useSelector((state) => state.auth.authenticated);

  const [open, setOpen] = React.useState(false);

  const fadeTime = 4000;

  const message = useSelector((state) => Object.values(state.message));
  const [severity, text, display] = message;

  useEffect(() => {
    if (display) {
      setOpen(true);
    }
    const timeoutId = setTimeout(() => dispatch(clearMessage()), fadeTime);

    return function cleanup() {
      clearTimeout(timeoutId);
      dispatch(clearMessage());
    };
    // eslint-disable-next-line
  }, [display]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Router history={history}>
        <Switch>
          <Route
            render={(props) =>
              authenticated ? <Redirect to="/" /> : <Login {...props} />
            }
            path={routes.LOGIN}
            exact
          />

          <CustomRoute
            restricted
            path={routes.ROOT}
            exact
            component={Dashboard}
          />

          <CustomRoute
            restricted
            path={routes.UPLOAD_VIDEO}
            exact
            component={UploadVideo}
          />

          <CustomRoute
            restricted
            path={routes.UPLOAD_PODCAST}
            exact
            component={UploadPodcast}
          />
          <CustomRoute
            restricted
            path={routes.UPLOAD_PODCAST}
            exact
            component={UploadPodcast}
          />
          <CustomRoute
            restricted
            path={routes.UPLOAD_LETTER}
            exact
            component={UploadLetter}
          />
          <CustomRoute
            restricted
            path={routes.UPLOAD_NEWS}
            exact
            component={UploadNews}
          />
          <CustomRoute
            restricted
            path={routes.UPLOAD_HEALTH}
            exact
            component={UploadHealth}
          />
          <CustomRoute
            restricted
            path={routes.LIST_VIDEO}
            exact
            component={ListVideo}
          />
          <CustomRoute
            restricted
            path={routes.LIST_PODCAST}
            exact
            component={ListPodcast}
          />
          <CustomRoute
            restricted
            path={routes.LIST_LETTER}
            exact
            component={ListLetter}
          />
          <CustomRoute
            restricted
            path={routes.LIST_NEWS}
            exact
            component={ListNews}
          />
          <CustomRoute
            restricted
            path={routes.LIST_HEALTH}
            exact
            component={ListHealth}
          />
          <CustomRoute
            restricted
            path={routes.ADD_CATEGORY}
            exact
            component={AddCategory}
          />
          <CustomRoute
            restricted
            path={routes.ALL_CATEGORY}
            exact
            component={AllCategory}
          />
          <CustomRoute
            restricted
            path={routes.COACHING_ADD_ITEM}
            exact
            component={AddItem}
          />
          <CustomRoute
            restricted
            path={routes.COACHING_VIEW_ALL_ITEM}
            exact
            component={ViewAllItems}
          />
        </Switch>
      </Router>
      <Snackbar open={open} autoHideDuration={fadeTime} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity || "error"}>
          <p className="message">{text}</p>
        </Alert>
      </Snackbar>
    </>
  );
};

export default App;
