import React from "react";

import Skeleton from "Components/Dashboard/Skeleton";
import FormHealth from "./FormHealth";

const UploadHealth = () => {
  return (
    <div>
      <Skeleton>
        <FormHealth title="Spiri Health" />
      </Skeleton>
    </div>
  );
};

export default UploadHealth;
