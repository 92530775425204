import React from "react";
import { MdEdit } from "react-icons/md";

import styles from "./UiComponents.module.scss";

const TableRow = ({
  onEditClick,
  index,
  title,
  date,
  category,
  content,
  day,
  year,
  week,
  id,
  type,
  modalTitle,
}) => {
  const renderCategoryRow = () => {
    if (type !== "spiriNews") {
      return <td>{category}</td>;
    }

    return null;
  };

  // const renderContionalRow = () => {
  //   if (year) {
  //     return (
  //       <>
  //         <td>{year}</td>
  //         <td>{week}</td>
  //       </>
  //     );
  //   } else if (date) {
  //     return (
  //       <>
  //         <td>{date}</td>
  //       </>
  //     );
  //   }
  //   return null;
  // };
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{title}</td>
      {category ? renderCategoryRow() : null}
      {content ? <td>{content}</td> : null}
      {type && ( type === "spiriNews" || type === 'spiriHealth') ? null : type && day ? <td>{day}</td> : type ? <td> - </td> : null}
      {type && (type === "spiriNews"|| type === 'spiriHealth') ? null : type && week ? <td>{week}</td> : type ? <td> - </td> : null}
      {type && ( type === "spiriNews"|| type === 'spiriHealth') ? null : type && year ? <td>{year}</td> : type ? <td> - </td> : null}
      {date ? <td>{date}</td> : null}
      <td>
        <button
          onClick={() => onEditClick(id, type, modalTitle)}
          className={styles.editBtn}
        >
          <MdEdit />
          <p>Edit</p>
        </button>
      </td>
    </tr>
  );
};

export default TableRow;
