import { mapKeys } from "lodash";

import {
  UPLOAD_HEALTH,
  GET_HEALTH,
  UPDATE_HEALTH,
  GET_ALL_HEALTH,
  EMPTY_STATE_HEALTH,
} from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_HEALTH:
      return { ...state, ...mapKeys(action.payload.data, "_id") };
    case UPLOAD_HEALTH:
      return { [action.payload._id]: { ...action.payload }, ...state };
    case GET_HEALTH:
      return { ...state, [action.payload._id]: action.payload };
    case UPDATE_HEALTH:
      console.log(action.payload);
      return {
        ...state,
        [action.payload.spiriHealthId]: {
          ...action.payload,
          _id: action.payload.spiriHealthId,
        },
      };
    case EMPTY_STATE_HEALTH:
      return action.payload;
    default:
      return state;
  }
}
