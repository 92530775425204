import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { string, object } from "yup";
import LinearProgress from "@material-ui/core/LinearProgress";

import { addCategory, updateCategory } from "../../../actions/category";
import { hideModal } from "actions/modal";

const CatForm = ({ modal }) => {
  const dispatch = useDispatch();

  // redux State
  const messageDisplay = useSelector((state) => state.message.display);
  const modalId = useSelector((state) => state.modal.id);
  const categoryDetail = useSelector((state) => state.category[modalId]);

  // State
  const [loaderDisplay, setLoaderDisplay] = useState(0);

  useEffect(() => {
    // for loader to stop after form is submitted
    setLoaderDisplay(0);
  }, [messageDisplay]);

  const formik = useFormik({
    initialValues: {
      category: modal ? categoryDetail && categoryDetail.category : "",
      subcategory: modal ? categoryDetail && categoryDetail.subcategory : "",
      englishSubcategory: modal
        ? categoryDetail && categoryDetail.englishSubcategory
        : "",
    },
    validationSchema: object({
      category: string().required("Required"),
      subcategory: string().required("Required"),
      englishSubcategory: string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (modal) {
        values.categoryId = modalId;
      }

      modal ? dispatch(updateCategory(values)) : dispatch(addCategory(values));

      resetForm();

      if (!modal) {
        setLoaderDisplay(1);
      } else {
        dispatch(hideModal());
      }
    },
  });

  const btnStatus = (isValid, dirty) => {
    // checking if upload or edit

    if (!modal) {
      return !(isValid && dirty);
    }
    return false;
  };

  return (
    <Card>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Card.Header>
          <Card.Title>{modal ? "Update Category" : "Add Category"} </Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Group controlId="formCategory">
            <Form.Label>Category</Form.Label>
            <Form.Control
              name="category"
              as="select"
              onChange={modal ? false : formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.category}
              isValid={formik.touched.category && !formik.errors.category}
              isInvalid={formik.touched.category && formik.errors.category}
            >
              <option defaultValue>Select an Option</option>
              <option value="spiriVedio">Spiri Video</option>
              <option value="spiriPodcast">Spiri Podcast</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.category}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="EnglishFormsubcategory">
            <Form.Label>Add Sub Category</Form.Label>
            <Form.Control
              name="englishSubcategory"
              type="text"
              placeholder="Sub Category"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.englishSubcategory}
              isValid={
                formik.touched.englishSubcategory &&
                !formik.errors.englishSubcategory
              }
              isInvalid={
                formik.touched.englishSubcategory &&
                formik.errors.englishSubcategory
              }
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.englishSubcategory}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formsubcategory">
            <Form.Label>Add Sub Category(German)</Form.Label>
            <Form.Control
              name="subcategory"
              type="text"
              placeholder="Sub Category"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.subcategory}
              isValid={formik.touched.subcategory && !formik.errors.subcategory}
              isInvalid={
                formik.touched.subcategory && formik.errors.subcategory
              }
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.subcategory}
            </Form.Control.Feedback>
          </Form.Group>
        </Card.Body>

        <Card.Footer>
          <Button
            disabled={btnStatus(formik.isValid, formik.dirty)}
            variant="primary"
            type="submit"
          >
            Submit
          </Button>
        </Card.Footer>
        {loaderDisplay ? <LinearProgress /> : false}
      </Form>
    </Card>
  );
};

export default CatForm;
