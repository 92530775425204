import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";

import TableRow from "Components/Dashboard/UiComponents/TableRow";
import { setModalId, showModal } from "actions/modal";
import Header from "Components/Dashboard/List/Header";
import useCategoriesList from "Components/customHooks/useCategoriesList";
import Modal from "Modal";

import Skeleton from "../Skeleton";

const AllCategory = () => {
  const dispatch = useDispatch();

  const [switchStatus, setSwitchStatus] = useState(true);
  const [cat, setCat] = useState("spiriPodcast");

  const modalStatus = useSelector((state) => state.modal.visibility);

  function onEditClick(id) {
    dispatch(showModal());
    dispatch(setModalId(id));
  }

  const handleChange = (event) => {
    setSwitchStatus(event.target.checked);
    event.target.checked ? setCat("spiriPodcast") : setCat("spiriVedio");
  };

  const categoriesList = useCategoriesList(cat);

  const renderTableRow = () => {
    return categoriesList.map((item, index) => {
      return (
        <TableRow
          key={`list${item._id}`}
          index={index}
          title={item.englishSubcategory}
          category={item.subcategory}
          onEditClick={onEditClick}
          id={item._id}
        />
      );
    });
  };

  return (
    <Skeleton>
      <Header
        title="All Category"
        home
        text1="Video"
        text2="Podcast"
        handleChange={handleChange}
        switchStatus={switchStatus}
      />
      <Table striped bordered hover>
        <tbody>{renderTableRow()}</tbody>
      </Table>
      {modalStatus ? <Modal type="category" title="Category" /> : ""}
    </Skeleton>
  );
};

export default AllCategory;
