import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { string, object } from "yup";
import Switch from "@material-ui/core/Switch";
import cx from "classnames";
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  getDayWeek,
  uploadSpiriCoaching,
  updateCoaching,
} from "../../../actions/coaching";
import Input from "../UiComponents/Form/Input";
import Description from "../UiComponents/Form/Description";
import Select from "../UiComponents/Form/Select";
import { getNameOfDay } from "../../../utils";
import { hideModal } from "actions/modal";
import useHomeData from "Components/customHooks/useHomeData";

import formStyles from "Components/Dashboard/Upload/Upload.module.css";
import styles from "./Coaching.module.css";

const CoachingForm = ({ modal }) => {
  const dispatch = useDispatch();

  const [lang, setLang] = useState("de");
  const [day, setDay] = useState(0);
  const [formSession, setFormSession] = useState([]);
  const [data, setData] = useState({});
  const [file, setFile] = useState(1);
  const [loaderDisplay, setLoaderDisplay] = useState(0);

  const coachingNumb = useHomeData().totalSpiriCoaching;

  useEffect(() => {
    // Calling Api to get previous value of day and week
    dispatch(getDayWeek({ language: lang }));
    // eslint-disable-next-line
  }, [lang, coachingNumb]);

  const messageDisplay = useSelector((state) => state.message.display);

  useEffect(() => {
    // for loader to stop after form is submitted
    setLoaderDisplay(0);
  }, [messageDisplay]);

  const lastWeek = useSelector(
    (state) => state.coaching.weekYear && state.coaching.weekYear.weekNumber
  );
  const lastYear = useSelector(
    (state) => state.coaching.weekYear && state.coaching.weekYear.year
  );

  const modalId = useSelector((state) => state.modal.id);
  const coachingDetail = useSelector((state) => state.coaching.list[modalId]);
  const modalSwitchStatus =
    coachingDetail && coachingDetail.language === "en" ? false : true;

  const [switchStatus, setSwitchStatus] = useState(
    modal ? modalSwitchStatus : true
  );

  const getWeekYear = () => {
    // if last week is 52 then increment year

    if (modal) {
      return { week: coachingDetail.weekNumber, year: coachingDetail.year };
    }

    if (lastWeek === 52) {
      return { week: 1, year: lastYear + 1 };
    }

    return { week: lastWeek + 1, year: lastYear };
  };

  const validationSchema = object({
    title: string().required("Required"),
    description: string().required("Required"),
    heading: string().required("Required"),
    category: data.category ? false : string().required("Required"),
  });

  const getIntialValues = () => {
    if (modal) {
      return {
        title:
          day === 0
            ? coachingDetail.title
            : coachingDetail.sessions[day - 1].title,
        heading:
          day === 0
            ? coachingDetail.heading
            : coachingDetail.sessions[day - 1].heading,
        description:
          day === 0
            ? coachingDetail.description
            : coachingDetail.sessions[day - 1].description,
        category:
          day === 0
            ? coachingDetail.category
            : coachingDetail.sessions[day - 1].category,
      };
    }

    return {
      title: "",
      heading: "",
      description: "",
      category: "",
    };
  };

  const onFormSubmit = (values, { resetForm }) => {
    const getSession = () => {
      const session = {};

      for (const key in values) {
        if (key === "title" || key === "description" || key === "heading") {
          session[key] = values[key];
        }
      }
      session.day = day;
      session.weekDay = getNameOfDay(lang, day); //day will be 1 when first time function is called
      if (modal) {
        session.users = coachingDetail.sessions[day - 1].users;
      }
      return session;
    };

    const fillSession = () => {
      const session = getSession();
      setFormSession([...formSession, session]);
    };

    // day 0 means  initial title and heading for whole data

    if (day === 0) {
      setData(values);
      resetForm();
      setDay(day + 1);
    } else if (day !== 7) {
      fillSession();
      setDay(day + 1);
      resetForm();
    } else {
      const session = getSession();

      //Setting session manually as state will not update for this point
      const newSession = [...formSession, session];

      const formData = new FormData();

      for (const key in data) {
        formData.append(key, data[key]);
      }

      formData.append("file", file);
      formData.append("sessions", JSON.stringify(newSession));
      formData.append("language", lang);
      formData.append("type", "spiriCoaching");
      formData.append("year", getWeekYear().year || 1);
      formData.append("weekNumber", getWeekYear().week || 1);

      // modal means edit

      if (modal) {
        formData.append("coachingId", modalId);
        formData.delete("language");
      }

      if (modal && (file === 0 || file === 1)) {
        formData.delete("file");
        formData.append("image", coachingDetail.image);
      }

      modal
        ? dispatch(updateCoaching(formData))
        : dispatch(uploadSpiriCoaching(formData));

      // EMPTY SESSION AND DATA
      setFormSession([]);
      setData({});

      if (!modal) {
        setLoaderDisplay(1);
        setFile(1);
        resetForm();
        setDay(0);
        document.querySelector("#file").value = "";
      } else {
        dispatch(hideModal());
      }
    }
  };

  const handleChangeSwitch = (event) => {
    if (day === 0 && !modal) {
      setSwitchStatus(event.target.checked);
      event.target.checked ? setLang("de") : setLang("en");
    }
  };

  const btnStatus = (isValid, dirty) => {
    // checking if upload or edit

    if (!modal) {
      return !(isValid && dirty && file && file !== 1);
    }
    return false;
  };

  const handleFileChange = (event) => {
    const fileArray =
      event.currentTarget.files[0] &&
      event.currentTarget.files[0].name.split(".");

    const fileExt = fileArray && fileArray[fileArray && fileArray.length - 1];
    if (fileExt === "png" || fileExt === "jpg") {
      setFile(event.currentTarget.files[0]);
    } else {
      // for showing error message
      // 0 means focused and file not there
      setFile(0);
    }
  };

  return (
    <Card className={modal ? formStyles.fixedHeight : false}>
      <Formik
        initialValues={getIntialValues()}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isValid,
          dirty,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Header>
              <Card.Title>
                {modal ? "Update Coaching" : "Add Coaching"}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className={cx(styles.titleContainer, "mb-5")}>
                <h2 className="font-weight-bold text-danger">DAY {day}</h2>
                <div className={styles.langContainer}>
                  <p>EN</p>
                  <Switch
                    checked={switchStatus}
                    onChange={handleChangeSwitch}
                    color="primary"
                    name="checked"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <p>DE</p>
                </div>
              </div>

              <Input
                name="title"
                type="text"
                placeholder="Title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                touched={touched.title}
                errors={errors.title}
              />

              <Select
                title="Category"
                name="category"
                onChange={data.category && day !== 0 ? null : handleChange}
                onBlur={handleBlur}
                value={
                  data.category && day !== 0 ? data.category : values.category
                }
                touched={touched.category}
                errors={errors.category}
              >
                <option value="">Choose...</option>
                <option value="love">Love</option>
                <option value="mind">Mind</option>
                <option value="soul">Soul</option>
                <option value="life">Life</option>
              </Select>

              <Input
                name="year"
                type="text"
                readOnly
                placeholder="Year"
                value={getWeekYear().year || 1}
              />

              <Input
                name="weekNumber"
                type="text"
                readOnly
                placeholder="Week"
                value={getWeekYear().week || 1}
              />

              <Input
                name="heading"
                type="text"
                placeholder="Heading"
                onChange={handleChange}
                value={values.heading}
                onBlur={handleBlur}
                touched={touched.heading}
                errors={errors.heading}
              />

              <Description
                title="Description"
                name="description"
                as="textarea"
                rows="3"
                placeholder="Enter ..."
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                touched={touched.description}
                errors={errors.description}
              />

              <Form.File
                style={day > 0 ? { display: "none" } : { display: "block" }}
                id="file"
              >
                <Form.File.Label>Upload File</Form.File.Label>
                <Form.File.Input
                  id="file"
                  name="file"
                  onBlur={handleBlur}
                  onChange={handleFileChange}
                />
                <Form.Text className="text-muted">
                  format: jpg and png, ideal resolution: 1280X720
                </Form.Text>
                <p className={styles.feedback} type="valid">
                  {file ? null : "Invalid File Format"}
                </p>
              </Form.File>
            </Card.Body>

            <Card.Footer>
              <Button
                disabled={btnStatus(isValid, dirty)}
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </Card.Footer>
            {loaderDisplay ? <LinearProgress /> : false}
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default CoachingForm;
