import React from "react";
import { IoIosArrowBack } from "react-icons/io";

import styles from "./UiComponents.module.scss";
import history from "../../../history";

const BackBtn = (props) => {
  return (
    <button onClick={() => history.push("/")} className={styles.backBtn}>
      <IoIosArrowBack />
      <p>Back</p>
    </button>
  );
};

export default BackBtn;
