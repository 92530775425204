import { MESSAGE, CLEAR_MESSAGE } from "actions/types";

const INITIAL_STATE = {
  severity: "",
  text: "",
  display: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case MESSAGE:
      return action.payload;
    case CLEAR_MESSAGE:
      return { ...state, display: false };
    default:
      return state;
  }
}
