import { SHOW_SIDEBAR, HIDE_SIDEBAR, TOOGLE_SIDEBAR } from "./types";

export const showSideBar = () => {
  return {
    type: SHOW_SIDEBAR,
    payload: true,
  };
};

export const hideSideBar = () => {
  return {
    type: HIDE_SIDEBAR,
    payload: false,
  };
};

export const toogleSidebar = () => {
  return {
    type: TOOGLE_SIDEBAR,
    payload: null,
  };
};
