import {
  ADD_CATEGORY,
  GET_ALL_CATEGORY,
  MESSAGE,
  UPDATE_CATEGORY,
} from "./types";
import spirify from "../apis/spirify";

export const addCategory = (data) => async (dispatch) => {
  try {
    const response = await spirify.post("category/add", data);
    // Creating data to send to reducer

    const payload = { ...data, ...response.data.data };
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Category Added successfully",
        display: true,
      },
    });

    dispatch({ type: ADD_CATEGORY, payload });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Failed to add Category",
        display: true,
      },
    });
  }
};

export const getAllCategory = (category) => async (dispatch) => {
  try {
    const response = await spirify.post("category/all", { category });
    dispatch({ type: GET_ALL_CATEGORY, payload: response.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Something went wrong",
        display: true,
      },
    });
  }
};

export const updateCategory = (data) => async (dispatch) => {
  try {
    await spirify.put("category/update", data);
    const formObj = {};
    for (const key in data) {
      if (key === "categoryId") {
        formObj["_id"] = data[key];
      } else {
        formObj[key] = data[key];
      }
    }

    dispatch({ type: UPDATE_CATEGORY, payload: formObj });
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Category updated successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Error while updating category",
        display: true,
      },
    });
  }
};
