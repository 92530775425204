import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaVideo, FaPodcast, FaRegNewspaper } from "react-icons/fa";
import { AiFillMessage } from "react-icons/ai";

import useHomeData from "Components/customHooks/useHomeData";

import styles from "./Skeleton.module.scss";

const InfoDisplay = () => {
  const dashDetail = useHomeData();

  const videoNumber = dashDetail.totalSpiriVedio;
  const podcastNumber = dashDetail.totalSpiriPodcast;
  const letterNumber = dashDetail.totalLoveLetters;
  const news = dashDetail.totalSpiriNews;

  return (
    <Row>
      <Col xs={6} sm={3}>
        <Link to="/listVideo" className={`${styles.smallBox} bg-info`}>
          <div className={styles.inner}>
            <h3>{videoNumber || "0"}</h3>
            <p>Videos</p>
          </div>
          <div className={styles.icon}>
            <FaVideo />
          </div>
        </Link>
      </Col>
      <Col xs={6} sm={3}>
        <Link to="/listPodcast" className={`${styles.smallBox} bg-success`}>
          <div className={styles.inner}>
            <h3>{podcastNumber || "0"}</h3>

            <p>Podcasts</p>
          </div>
          <div className={styles.icon}>
            <FaPodcast />
          </div>
        </Link>
      </Col>
      <Col xs={6} sm={3}>
        <Link to="/listLetter" className={`${styles.smallBox} bg-warning`}>
          <div className={styles.inner}>
            <h3>{letterNumber || "0"}</h3>
            <p>LoveLetters</p>
          </div>
          <div className={styles.icon}>
            <AiFillMessage />
          </div>
        </Link>
      </Col>
      <Col xs={6} sm={3}>
        <Link to="/listNews" className={`${styles.smallBox} bg-danger`}>
          <div className={styles.inner}>
            <h3>{news || "0"}</h3>
            <p>Spiri News</p>
          </div>
          <div className={styles.icon}>
            <FaRegNewspaper />
          </div>
        </Link>
      </Col>
    </Row>
  );
};

export default InfoDisplay;
