import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import { Link } from "react-router-dom";
import { Nav, Accordion } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import { FiUpload, FiList, FiGrid } from "react-icons/fi";
import { BsFillDiamondFill } from "react-icons/bs";

import {
  LIST_LETTER,
  LIST_PODCAST,
  LIST_VIDEO,
  LIST_NEWS,
  LIST_HEALTH,
  UPLOAD_LETTER,
  UPLOAD_PODCAST,
  UPLOAD_VIDEO,
  UPLOAD_NEWS,
  UPLOAD_HEALTH,
  ADD_CATEGORY,
  ALL_CATEGORY,
  COACHING_ADD_ITEM,
  COACHING_VIEW_ALL_ITEM,
} from "../../../../routes";

import { hideSideBar } from "actions/sideBar";

import styles from "./SideBar.module.scss";
import utility from "../../../utility.module.scss";
import history from "../../../../history";

import { ReactComponent as Logo } from "../../../../images/logo.svg";

const SideBar = (props) => {
  const dispatch = useDispatch();

  const [activePath] = useState(window.location.pathname);
  const sideBarStatus = useSelector((state) => state.sideBar);

  const getActiveKey = () => {
    if (activePath.includes("list")) {
      return "1";
    } else if (activePath.includes("Category")) {
      return "2";
    } else if (activePath.includes("Item")) {
      return "3";
    } else {
      return "0";
    }
  };

  const handleClick = (e, path) => {
    history.push(path);
    e.stopPropagation();
    dispatch(hideSideBar());
  };

  return (
    <aside
      className={cx(styles.sideBarDark, styles.elevation, {
        [styles.active]: sideBarStatus,
      })}
    >
      <Link to="/" className={styles.brandLink}>
        <div className="logo logo--dash">
          <Logo />
        </div>
        <p className={cx(styles.brandText, utility.fwLight)}>
          Spirify Admin Panel
        </p>
      </Link>

      <div className={styles.sideBar}>
        <Accordion defaultActiveKey={getActiveKey()}>
          <Accordion.Toggle as={Nav.Item} eventKey="0">
            <Nav.Link>
              <FiUpload />
              Upload
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, UPLOAD_VIDEO)}
                  active={activePath === UPLOAD_VIDEO ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Spiri Video
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, UPLOAD_PODCAST)}
                  active={activePath === UPLOAD_PODCAST ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Spiri Podcast
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, UPLOAD_LETTER)}
                  active={activePath === UPLOAD_LETTER ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Spiri Loveletter
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, UPLOAD_NEWS)}
                  active={activePath === UPLOAD_NEWS ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Spiri News
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, UPLOAD_HEALTH)}
                  active={activePath === UPLOAD_HEALTH ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Spiri Health
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>
          <Accordion.Toggle as={Nav.Item} eventKey="1">
            <Nav.Link>
              <FiList />
              All Post
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, LIST_VIDEO)}
                  active={activePath === LIST_VIDEO ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Spiri Video
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, LIST_PODCAST)}
                  active={activePath === LIST_PODCAST ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Spiri Podcast
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, LIST_LETTER)}
                  active={activePath === LIST_LETTER ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Spiri Loveletter
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, LIST_NEWS)}
                  active={activePath === LIST_NEWS ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Spiri News
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, LIST_HEALTH)}
                  active={activePath === LIST_HEALTH ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Spiri Health
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>

          <Accordion.Toggle as={Nav.Item} eventKey="2">
            <Nav.Link>
              <FiGrid />
              Categories
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, ADD_CATEGORY)}
                  active={activePath === ADD_CATEGORY ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Add Category
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, ALL_CATEGORY)}
                  active={activePath === ALL_CATEGORY ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  View All Categories
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>

          <Accordion.Toggle as={Nav.Item} eventKey="3">
            <Nav.Link>
              <BsFillDiamondFill />
              Spiri Coaching
            </Nav.Link>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, COACHING_ADD_ITEM)}
                  active={activePath === COACHING_ADD_ITEM ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  Add Coaching
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={(e) => handleClick(e, COACHING_VIEW_ALL_ITEM)}
                  active={activePath === COACHING_VIEW_ALL_ITEM ? true : false}
                >
                  <FaCircle
                    style={{ fontSize: "1.2rem", marginLeft: "1rem" }}
                  />
                  View All Coaching
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </aside>
  );
};

export default SideBar;
