import { combineReducers } from "redux";
import auth from "./auth";
import category from "./category";
import coaching from "./coaching";
import video from "./video";
import loveletter from "./loveletter";
import podcast from "./podcast";
import modal from "./modal";
import home from "./home";
import message from "./message";
import sideBar from "./sideBar";
import news from "./news";
import recent from "./recent";
import health from "./health";

export default combineReducers({
  auth,
  category,
  coaching,
  video,
  loveletter,
  podcast,
  modal,
  home,
  message,
  sideBar,
  news,
  recent,
  health,
});
