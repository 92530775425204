import axios from "axios";

const url =
  process.env.REACT_APP_ENV === "production"
    ? "https://spiriweb.de"
    : "http://52.59.123.97";

const spirify = axios.create({
  baseURL: `${url}/api/v1`,
  headers: {
    "x-api-key": process.env.REACT_APP_API_KEY,
    "Content-Type": "application/json",
  },
});

spirify.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default spirify;
