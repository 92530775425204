import { SET_MODAL_ID, SHOW_MODAL, HIDE_MODAL } from "./types";

export const setModalId = (id) => {
  return {
    type: SET_MODAL_ID,
    payload: id,
  };
};

export const showModal = () => {
  return{
    type: SHOW_MODAL,
    payload: true
  }
}

export const hideModal = () => {
  return{
    type: HIDE_MODAL,
    payload: false
  }
}
