import React from "react";

import { Form } from "react-bootstrap";

const Description = ({
  title,
  as,
  rows,
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  value,
  errors,
  touched,
}) => {
  return (
    <Form.Group controlId={`form-${name}`}>
      <Form.Label>{title}</Form.Label>
      <Form.Control
        name={name}
        type={type}
        as={as}
        rows={rows}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        isValid={touched && !errors}
        isInvalid={touched && errors}
      ></Form.Control>
      <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default Description;
