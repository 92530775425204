import { SHOW_SIDEBAR, HIDE_SIDEBAR, TOOGLE_SIDEBAR } from "../actions/types";

export default function (state = false, action) {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return true;
    case HIDE_SIDEBAR:
      return false;
    case TOOGLE_SIDEBAR:
      return !state;
    default:
      return state;
  }
}
