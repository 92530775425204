import React from "react";

import Skeleton from "../../Dashboard/Skeleton";
import FormPodcast from "./FormPodcast";

const UploadPodcast = () => {
  return (
    <div>
      <Skeleton>
        <FormPodcast title="Spiri Podcast" />
      </Skeleton>
    </div>
  );
};

export default UploadPodcast;
