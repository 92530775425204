import {
  UPLOAD_LOVELETTER,
  GET_LOVELETTERS,
  MESSAGE,
  GET_LOVELETTER,
  EMPTY_STATE_LOVELETTER,
  UPDATE_LOVELETTER,
} from "./types";
import spirify from "../apis/spirify";
import { getRecentData } from "./recent";

export const uploadLoveLetter = (data) => async (dispatch) => {
  try {
    const response = await spirify.post("love-letter/add", data);

    const formObj = {};
    data.forEach((value, key) => {
      if (key !== "file") {
        formObj[key] = value;
      }
    });

    formObj.createdAt = new Date().toISOString();
    const payload = { ...formObj, ...response.data.data };

    if (payload.language === "de") {
      dispatch({ type: UPLOAD_LOVELETTER, payload });
    }

    // de because even en form switch de after submit

    dispatch(getRecentData({ language: payload.language, type: "loveLetter" }));

    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Love Letter uploaded successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Error while uploading Love Letter",
        display: true,
      },
    });
  }
};

export const getLoveletterList = (language, start) => async (dispatch) => {
  try {
    const response = await spirify.post(
      `dashboard/love-letter/language/${start}/15`,
      {
        language,
      }
    );

    dispatch({ type: GET_LOVELETTERS, payload: response.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Something went wrong",
        display: true,
      },
    });
  }
};

export const getLoveletter = (id) => async (dispatch) => {
  try {
    const response = await spirify.get(`dashboard/love-letter/detail/${id}`);

    dispatch({ type: GET_LOVELETTER, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Something  went wrong",
        display: true,
      },
    });
  }
};

export const emptyStateLoveletter = () => {
  return {
    type: EMPTY_STATE_LOVELETTER,
    payload: {},
  };
};

export const updateLetter = (data, date) => async (dispatch) => {
  try {
    await spirify.put("love-letter/update", data);
    const formObj = {};
    data.forEach((value, key) => {
      if (key === "loveLetterId") {
        formObj["_id"] = value;
      } else {
        formObj[key] = value;
      }
    });

    const payload = { ...formObj, createdAt: date };

    dispatch({ type: UPDATE_LOVELETTER, payload });
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Love Letter updated successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Failed to update Love Letter",
        display: true,
      },
    });
  }
};
