import { GET_RECENT_DATA } from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_RECENT_DATA:
      if (action.payload.success) {
        return action.payload.data;
      }
      // day: 0 so +1 can be 1
      return { year: 1, week: 1, day: 0 };
    default:
      return state;
  }
}
