import React from "react";

import Skeleton from "../Skeleton";
import CoachingForm from "./CoachingForm";

export const AddItem = () => {
  return (
    <Skeleton>
      <CoachingForm />
    </Skeleton>
  );
};

export default AddItem;
