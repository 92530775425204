import {
  UPLOAD_VIDEO,
  GET_VIDEOS,
  MESSAGE,
  GET_VIDEO,
  UPDATE_VIDEO,
  EMPTY_STATE_VIDEO,
} from "./types";
import spirify from "../apis/spirify";
import { getRecentData } from "./recent";

export const uploadVideo = (data) => async (dispatch) => {
  try {
    const response = await spirify.post("spiri-vedio/add", data);

    // Creating data to send to reducer
    const formObj = {};
    data.forEach((value, key) => {
      if (key !== "file") {
        formObj[key] = value;
      }
    });
    formObj.createdAt = new Date().toISOString();

    const payload = { ...formObj, ...response.data.data };

    // For showing intial data de on home screen
    if (payload.language === "de") {
      dispatch({ type: UPLOAD_VIDEO, payload: payload });
    }

    // de because even en form switch de after submit

    dispatch(getRecentData({ language: payload.language, type: "spiriVideo" }));

    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Video Uploaded successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "There was error while uploading video",
        display: true,
      },
    });
  }
};

export const getVideoList = (language, start) => async (dispatch) => {
  try {
    const response = await spirify.post(
      `dashboard/spiri-vedio/language/${start}/15`,
      {
        language,
      }
    );

    dispatch({ type: GET_VIDEOS, payload: response.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Something went wrong",
        display: true,
      },
    });
  }
};

export const getVideo = (id) => async (dispatch) => {
  try {
    const response = await spirify.get(`dashboard/spiri-vedio/detail/${id}`);

    dispatch({ type: GET_VIDEO, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Something went wrong",
        display: true,
      },
    });
  }
};

export const updateVideo = (data, date) => async (dispatch) => {
  try {
    await spirify.put("spiri-vedio/update", data);
    const formObj = {};
    data.forEach((value, key) => {
      if (key === "spiriVedioId") {
        formObj["_id"] = value;
      } else {
        formObj[key] = value;
      }
    });

    const payload = { ...formObj, createdAt: date };

    dispatch({ type: UPDATE_VIDEO, payload });
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "success",
        text: "Video updated successfully",
        display: true,
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        severity: "error",
        text: "Failed to update video",
        display: true,
      },
    });
  }
};

export const emptyStateVideo = () => {
  return {
    type: EMPTY_STATE_VIDEO,
    payload: {},
  };
};
