export const getNameOfDay = (lang, day) => {
  const daysGerman = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ];

  const daysEnglish = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return lang === "en" ? daysEnglish[day - 1] : daysGerman[day - 1];
};

const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getFormatedDate = (isoDate) => {
  const fDate = new Date(isoDate);
  const year = fDate.getFullYear();
  const month = fDate.getMonth();
  let date = fDate.getDate();

  if (date < 10) {
    date = `0${date}`;
  }

  return `${year}-${monthArray[month]}-${date}`;
};

export const getMonth = (number) => {
  return monthArray[number - 1];
};

export const isInBetween = (value1, value2, value) => {
  if (
    Object.prototype.toString.call(value1) === "[object Date]" &&
    Object.prototype.toString.call(value2) === "[object Date]"
  ) {
    if (!isNaN(value1.getTime()) && !isNaN(value2.getTime())) {
      const newValue = new Date(value.split("T")[0]).toISOString();
      return (
        newValue >= value1.toISOString() && newValue <= value2.toISOString()
      );
    }

    return false;
  }
  return false;
};

export const getCurrentYWD = ({ year, week, day }) => {
  if (week < 52 && week % 7 === 0) {
    return { year, week: week + 1, day: 1 };
  } else if (week === 52) {
    return { year: year + 1, week: 1, day: 1 };
  } else {
    return { year, week, day: day + 1 };
  }
};
