import React from "react";

import { Form } from "react-bootstrap";

const Select = ({
  title,
  name,
  onChange,
  onBlur,
  value,
  touched,
  errors,
  children,
}) => {
  return (
    <Form.Group controlId={`form-${name}`}>
      <Form.Label>{title}</Form.Label>
      <Form.Control
        name={name}
        as="select"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        isValid={touched && !errors}
        isInvalid={touched && errors}
      >
        {children}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default Select;
