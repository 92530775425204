import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { MdMenu } from "react-icons/md";

import { signout } from "actions/auth";
import { toogleSidebar } from "actions/sideBar";
import history from "../../../history";

import styles from "./Skeleton.module.scss";

const TopBar = () => {
  const dispatch = useDispatch();

  const sideBarStatus = useSelector((state) => state.sideBar);

  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(toogleSidebar());
  };

  return (
    <nav
      className={cx(styles.topBar, {
        [styles.active]: !sideBarStatus,
      })}
    >
      <button onClick={handleClick} className={styles.btn}>
        <MdMenu />
      </button>
      <button onClick={() => dispatch(signout(() => history.push("/login")))}>
        Logout
      </button>
    </nav>
  );
};

export default TopBar;
