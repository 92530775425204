import React from "react";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import styles from "./List.module.css";

const Header = ({
  title,
  handleChange,
  switchStatus,
  text1,
  text2,
  home,
  handleToChangeP,
  handleFromChangeP,
}) => {
  const [fromDate, setFromDate] = React.useState(new Date("2020-01-01"));
  const [toDate, setToDate] = React.useState(new Date());

  const handleFromChange = (date) => {
    setFromDate(date);
    handleFromChangeP(date);
  };

  const handleToChange = (date) => {
    setToDate(date);
    handleToChangeP(date);
  };

  const renderDatePicker = () => {
    if (!home) {
      return (
        <div className={styles.filter}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid
              style={{ marginRight: "1rem" }}
              container
              justify="space-around"
            >
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-from-inline"
                label="From"
                value={fromDate}
                onChange={handleFromChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-to-inline"
                label="TO"
                value={toDate}
                onChange={handleToChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      );
    }

    return false;
  };

  return (
    <div className={styles.title}>
      <div className={styles.left}>
        <h2>{title}</h2>

        <div className="langContainer mb-0">
          <p>{text1 || "EN"}</p>
          <Switch
            checked={switchStatus}
            onChange={handleChange}
            color="primary"
            name="checked"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <p>{text2 || "DE"}</p>
        </div>

        {renderDatePicker()}
      </div>
    </div>
  );
};

export default Header;
