import React, { useState, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { string, object } from "yup";
import Switch from "@material-ui/core/Switch";
import LinearProgress from "@material-ui/core/LinearProgress";

import Input from "../UiComponents/Form/Input";
import Select from "../UiComponents/Form/Select";
import Description from "../UiComponents/Form/Description";
import { uploadLoveLetter, updateLetter } from "../../../actions/loveletter";
import { hideModal } from "actions/modal";

import styles from "./Upload.module.css";

const FormLetter = ({ title, modal }) => {
  const dispatch = useDispatch();

  const type = "loveLetter";

  //redux state
  const messageDisplay = useSelector((state) => state.message.display);
  const modalId = useSelector((state) => state.modal.id);
  const loveLetterDetail = useSelector((state) => state.loveletter[modalId]);
  const modalSwitchStatus =
    loveLetterDetail && loveLetterDetail.language === "en" ? false : true;

  // State
  const [lang, setLang] = useState("de");
  const [file, setFile] = useState(1);
  const [loaderDisplay, setLoaderDisplay] = useState(0);

  const [switchStatus, setSwitchStatus] = useState(
    modal ? modalSwitchStatus : true
  );

  useEffect(() => {
    // for loader to stop after form is submitted
    setLoaderDisplay(0);

    if (window.location.pathname === "/uploadLetter") {
      window.scrollTo(0, 0);
    }
  }, [messageDisplay]);

  //modifying intial value on the basis of edit or add
  const initialValues = {
    title: modal ? loveLetterDetail && loveLetterDetail.title : "",
    description: modal ? loveLetterDetail && loveLetterDetail.description : "",
    type: type,
    category: modal ? loveLetterDetail && loveLetterDetail.category : "",
    content: modal ? loveLetterDetail && loveLetterDetail.content : "",
    heading: modal ? loveLetterDetail && loveLetterDetail.heading : "",
    language: modal ? loveLetterDetail && loveLetterDetail.language : lang, // intialvalue is called in first render, so declare value at render method
    day: modal ? loveLetterDetail && loveLetterDetail.day : "",
    week: modal ? loveLetterDetail && loveLetterDetail.week : "",
    year: modal ? loveLetterDetail && loveLetterDetail.year : "",
  };

  const schema = object({
    title: string().required("Required"),
    description: string().required("Required"),
    category: string().required("Required"),
    content: string().required("Required"),
    heading: string().required("Required"),
  });

  const onFormSubmit = (values, { resetForm }) => {
    const formData = new FormData(); // Currently empty

    for (const key in values) {
      formData.append(key, values[key]);
    }
    formData.append("file", file);

    if (modal) {
      formData.append(`${type}Id`, modalId);
    }

    if (modal && (file === 0 || file === 1)) {
      formData.delete("file");
      formData.append("picture", loveLetterDetail.picture);
    }

    if (values.content !== "scheduled") {
      formData.delete("releaseDate");
    }

    modal
      ? dispatch(updateLetter(formData, loveLetterDetail.createdAt))
      : dispatch(uploadLoveLetter(formData));

    document.querySelector("#file").value = "";
    resetForm();

    if (!modal) {
      setLoaderDisplay(1);
      setFile(1);
      document.querySelector("#file").value = "";
    } else {
      dispatch(hideModal());
    }
  };

  const handleChangeSwitch = (event) => {
    //do not allow switch change if editing
    // user can not change lang while editing
    if (!modal) {
      setSwitchStatus(event.target.checked);
      event.target.checked ? setLang("de") : setLang("en");
    }
  };

  const handleFileChange = (event) => {
    const fileArray =
      event.currentTarget.files[0] &&
      event.currentTarget.files[0].name.split(".");

    const fileExt = fileArray && fileArray[fileArray && fileArray.length - 1];
    if (fileExt === "png" || fileExt === "jpg") {
      setFile(event.currentTarget.files[0]);
    } else {
      // for showing error message
      // 0 means focused and file not there
      setFile(0);
    }
  };

  const btnStatus = (isValid, dirty) => {
    // checking if upload or edit

    if (!modal) {
      return !(isValid && dirty && file && file !== 1);
    }
    return false;
  };

  return (
    <Card className={modal ? styles.fixedHeight : false}>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onFormSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isValid,
          dirty,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Header>
              <Card.Title
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {title} - {lang}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="langContainer">
                <p>EN</p>
                <Switch
                  checked={switchStatus}
                  onChange={handleChangeSwitch}
                  color="primary"
                  name="checked"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <p>DE</p>
              </div>
              <Input
                name="title"
                type="text"
                placeholder="Title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                touched={touched.title}
                errors={errors.title}
              />

              <Input
                name="heading"
                type="text"
                placeholder="Heading"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.heading}
                touched={touched.heading}
                errors={errors.heading}
              />

              <Description
                title="Description"
                name="description"
                as="textarea"
                rows="3"
                placeholder="Enter ..."
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                touched={touched.description}
                errors={errors.description}
              />

              <Select
                title="Category"
                name="category"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.category}
                touched={touched.category}
                errors={errors.category}
              >
                <option value="">Choose...</option>
                <option value="love">Love</option>
                <option value="mind">Mind</option>
                <option value="soul">Soul</option>
                <option value="life">Life</option>
              </Select>

              <Select
                title="Content"
                name="content"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.content}
                touched={touched.content}
                errors={errors.content}
              >
                <option value="">Choose...</option>
                <option value="preview">Preview</option>
                <option value="basic">Basic</option>
                <option value="scheduled">Scheduled</option>
              </Select>

              {values.content === "scheduled" ? (
                <>
                  <Input
                    name="day"
                    type="number"
                    placeholder="Day"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.day}
                    touched={touched.day}
                    errors={errors.day}
                  />
                  <Input
                    name="week"
                    type="number"
                    placeholder="Week"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.week}
                    touched={touched.week}
                    errors={errors.week}
                  />
                  <Input
                    name="year"
                    type="number"
                    placeholder="Year"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.year}
                    touched={touched.year}
                    errors={errors.year}
                  />
                </>
              ) : null}

              <Form.File className="mb-3" id="fileInput">
                <Form.File.Label>Upload Image</Form.File.Label>
                <Form.File.Input
                  id="file"
                  name="file"
                  onBlur={handleBlur}
                  onChange={handleFileChange}
                />
                <Form.Text className="text-muted">
                  format: jpg and png, ideal resolution: 1280X720
                </Form.Text>
                <p className={styles.feedback} type="valid">
                  {file ? null : "Invalid File Format"}
                </p>
              </Form.File>
            </Card.Body>
            <Card.Footer>
              <Button
                disabled={btnStatus(isValid, dirty)}
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </Card.Footer>
            {loaderDisplay ? <LinearProgress /> : false}
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default FormLetter;
