import { DASH_DETAIL, MESSAGE } from "./types.js";
import spirify from "../apis/spirify";

export const getDashDetail = () => async (dispatch) => {
  try {
    const response = await spirify.get("dashboard/home");

    dispatch({ type: DASH_DETAIL, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: { severity: "error", text: "Something went wrong",display: true },
    });
  }
};
