import { SET_MODAL_ID, SHOW_MODAL, HIDE_MODAL } from "../actions/types";

const INITIAL_STATE = {
  id: null,
  visibility: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_MODAL_ID:
      return { ...state, id: action.payload };
    case SHOW_MODAL:
      return { ...state, visibility: action.payload };
    case HIDE_MODAL:
      return { ...state, visibility: action.payload };
    default:
      return state;
  }
}
