import { mapKeys } from "lodash";

import {
  GET_PODCASTS,
  UPLOAD_PODCAST,
  GET_PODCAST,
  EMPTY_STATE_PODCAST,
  UPDATE_PODCAST,
} from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_PODCASTS:
      return { ...state, ...mapKeys(action.payload, "_id") };
    case UPLOAD_PODCAST:
      return { [action.payload._id]: action.payload, ...state };
    case GET_PODCAST:
      return { ...state, [action.payload._id]: action.payload };
    case UPDATE_PODCAST:
      return { ...state, [action.payload._id]: action.payload };
    case EMPTY_STATE_PODCAST:
      return action.payload;
    default:
      return state;
  }
}
