import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";

import TableRow from "../UiComponents/TableRow";
import Modal from "../../../Modal";
import Skeleton from "../Skeleton";
import { getFormatedDate } from "../../../utils";
import usePodcastList from "../../customHooks/usePodcastList";
import { getPodcast, emptyStatePodcast } from "../../../actions/podcast";
import { setModalId, showModal } from "actions/modal";
import Header from "./Header";
import { isInBetween } from "utils";

const ListPodcast = () => {
  const dispatch = useDispatch();
  const [switchStatus, setSwitchStatus] = useState(true);
  const [lang, setLang] = useState("de");
  const [fromDateP, setFromDateP] = React.useState(new Date("2020-01-01"));
  const [toDateP, setToDateP] = React.useState(new Date());
  const [callStart, setCallStart] = useState(0);

  const podcastList = usePodcastList(lang, callStart);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const totalHeight = document.body.offsetHeight;
      const scrollPos = window.scrollY;

      // Various condition to pagination 3 one is stoping
      //it for further api call if data returned is empty

      if (
        scrollPos + 10 > totalHeight - windowHeight &&
        windowHeight > 400 &&
        podcastList.length >= callStart
      ) {
        setCallStart(callStart + 15);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [callStart, podcastList]);

  const modalStatus = useSelector((state) => state.modal.visibility);

  const handleToChangeP = (date) => {
    setToDateP(date);
  };

  const handleFromChangeP = (date) => {
    setFromDateP(date);
  };

  function onEditClick(id) {
    dispatch(showModal());
    dispatch(getPodcast(id));
    dispatch(setModalId(id));
  }

  const handleChange = (event) => {
    setSwitchStatus(event.target.checked);
    //emptying the local state
    dispatch(emptyStatePodcast());
    event.target.checked ? setLang("de") : setLang("en");
    setCallStart(0);
  };


  const renderTableRow = () => {
    return podcastList
      .filter((item) => isInBetween(fromDateP, toDateP, item.createdAt))
      .map((item, index) => {
        return (
          <TableRow
            index={index}
            key={`podcast${item._id}`}
            title={item.title}
            date={getFormatedDate(item.createdAt)}
            category={item.category}
            content={item.content}
            day = {item.day}
            week ={item.week}
            year ={item.year}
            type='spiriPodcast'
            onEditClick={onEditClick}
            id={item._id}
          />
        );
      });
  };

  return (
    <Skeleton>
      <Header
        title="Spiri Podcast"
        handleChange={handleChange}
        switchStatus={switchStatus}
        handleToChangeP={handleToChangeP}
        handleFromChangeP={handleFromChangeP}
      />
      <Table striped bordered hover>
      <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Category</th>
            <th>Content</th>
            <th>day</th>
            <th>Week</th>
            <th>Year</th>
            <th>Created On</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderTableRow()}</tbody>
      </Table>

      {modalStatus ? <Modal type="spiriPodcast" title="Spiri Poadcast" /> : ""}
    </Skeleton>
  );
};

export default ListPodcast;
