import React from "react";

import Skeleton from "Components/Dashboard/Skeleton";
import FormNews from "./FormNews";

const UploadNews = () => {
  return (
    <div>
      <Skeleton>
        <FormNews title="Spiri News" />
      </Skeleton>
    </div>
  );
};

export default UploadNews;
