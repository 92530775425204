import { mapKeys } from "lodash";

import {
  UPLOAD_NEWS,
  GET_NEWS,
  EMPTY_STATE_NEWS,
  UPDATE_NEWS,
  GET_NEWS_LIST,
} from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_NEWS_LIST:
      return { ...state, ...mapKeys(action.payload, "_id") };
    case UPLOAD_NEWS:
      return { [action.payload._id]: action.payload, ...state };
    case GET_NEWS:
      return { ...state, [action.payload._id]: action.payload };
    case UPDATE_NEWS:
      return { ...state, [action.payload._id]: action.payload };
    case EMPTY_STATE_NEWS:
      return action.payload;
    default:
      return state;
  }
}
