import React from "react";

import CatForm from "./CatForm";
import Skeleton from "../Skeleton";

const AddCategory = () => {
  return (
    <Skeleton>
      <CatForm />
    </Skeleton>
  );
};

export default AddCategory;
