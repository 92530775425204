import { mapKeys } from "lodash";

import {
  GET_VIDEOS,
  UPLOAD_VIDEO,
  GET_VIDEO,
  UPDATE_VIDEO,
  EMPTY_STATE_VIDEO,
} from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_VIDEOS:
      return { ...state, ...mapKeys(action.payload.data, "_id") };
    case UPLOAD_VIDEO:
      return { [action.payload._id]: action.payload, ...state };
    case GET_VIDEO:
      return { ...state, [action.payload._id]: action.payload };
    case UPDATE_VIDEO:
      return { ...state, [action.payload._id]: action.payload };
    case EMPTY_STATE_VIDEO:
      return action.payload;
    default:
      return state;
  }
}
