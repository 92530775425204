import { AUTH_USER, MESSAGE } from "./types";
import spirify from "../apis/spirify";

export const signin = (data) => async (dispatch) => {
  try {
    const response = await spirify.post("signInDashboard", data);
    dispatch({ type: AUTH_USER, payload: response.data.data.token });
    localStorage.setItem("token", response.data.data.token);
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: { severity: "error", text: "Invalid Email or password", display: true },
    });
  }
};

export const signout = (callback) => {
  localStorage.removeItem("token");
  callback();
  return {
    type: AUTH_USER,
    payload: "",
  };
};
